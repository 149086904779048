// Our modules / classes

import Navigation from "./modules/Navigation";
import Slider from "./modules/Slider";
import RodoModal from "./modules/rodo";
import Animation from "./modules/Animations";

// // Instantiate a new object using our modules/classes

const navigation = new Navigation();
const slider = new Slider();
const rodo = new RodoModal();
const animations = new Animation();

function handleFirstTab(e) {
	if (e.defaultPrevented) {
		return;
	}

	let key = e.key || e.keyCode;

	if (key === "Tab" || key === 9) {
		document.body.classList.add("user-is-tabbing");
		window.removeEventListener("keydown", handleFirstTab);
	}
}

window.addEventListener("keydown", handleFirstTab);
